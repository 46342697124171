import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Aligner = makeShortcode("Aligner");
const Button = makeShortcode("Button");
const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-van-gogh-painting-love-and-purpose",
      "style": {
        "position": "relative"
      }
    }}>{`🎨 Van Gogh: Painting, Love and Purpose`}<a parentName="h1" {...{
        "href": "#-van-gogh-painting-love-and-purpose",
        "aria-label": " van gogh painting love and purpose permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`Fulfilling its function`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`completely`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`an empty boat rests`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`against the bullrushes,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`shepherding the moon`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`as right words flow`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`out of mind, leaving`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`waters soft against wood,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`brushes of boundless life.`}</em>{`  `}</p>
        <p><img parentName="p" {...{
            "src": "/images/mod0-img/vangoghselfportrait.jpg",
            "alt": "Van Gogh"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://www.themarginalian.org/2014/12/01/van-gogh-purpose-letter/" mdxType="Button">Love what you do</Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <h2 {...{
      "id": "how-does-this-fit-in",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit in?`}<a parentName="h2" {...{
        "href": "#how-does-this-fit-in",
        "aria-label": "how does this fit in permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We will end our introduction to Kernel with a letter sent by Vincent Van Gogh to his brother in 1879. Throughout the rest of this book, we will describe various ways to think about purpose, intention, and living honestly a fulfilled and wholesome life. Van Gogh pointed at these ways most poignantly and largely without words, for which we love him very deeply. The brief to follow will hopefully assist you:`}</p>
    <Process mdxType="Process">
      <p>{`Ask better questions`}</p>
      <p>{`Think deeply about your intention`}</p>
      <p>{`Reflect on how you define both 'value' and 'trust'`}</p>
      <p>{`Think about what you, personally, are doing here? What is your question?`}</p>
    </Process>
    <h2 {...{
      "id": "full-brief",
      "style": {
        "position": "relative"
      }
    }}>{`Full Brief`}<a parentName="h2" {...{
        "href": "#full-brief",
        "aria-label": "full brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <blockquote>
      <p parentName="blockquote">{`"Improvement in my life — should I not desire it or should I not be in need of improvement? I really want to improve. But it’s precisely because I yearn for it that I’m afraid of remedies that are worse than the disease." `}</p>
    </blockquote>
    <p>{`How do we handle this tension between improving and being joyfully who we already are?`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Such idling is really a rather strange sort of idling. It’s rather difficult for me to defend myself on this score, but I would be sorry if you couldn’t eventually see this in a different light."`}</p>
    </blockquote>
    <p>{`Van Gogh presents tinkering as a better way of being. In our own time, it may not be about building more products for 'consumers', but rather using new `}<a parentName="p" {...{
        "href": "/learn/module-0/trust"
      }}>{`trust spaces`}</a>{` to commune in a different and digital sense.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Such a person doesn’t always know himself what he could do, but he feels by instinct, I’m good for something, even so! I feel I have a `}<em parentName="p">{`raison d’être`}</em>{`! I know that I could be a quite different man! For what then could I be of use, for what could I serve! There’s something within me, `}<strong parentName="p">{`so what is it!"`}</strong></p>
    </blockquote>
    <p>{`What is in your heart of hearts? You may wonder how relevant this question is, but we cannot build a better web if we do not first understand what it might mean to be better ourselves. And that requires deep enquiry into the nature of self: there is no other way.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"But what’s your ultimate goal, you’ll say. The goal will become clearer, will take shape slowly and surely, as the croquis becomes a sketch and the sketch a painting, as one works more seriously, as one digs deeper into the originally vague idea, the first fugitive, passing thought."`}</p>
    </blockquote>
    <p>{`Do you trust the inner voice? Again, a seemingly abstract question with profound implications for what we actually, in practice, understand trust to be; which is then directly reflected in the kinds of products we build.`}</p>
    <blockquote>
      <p parentName="blockquote">{`"I’m always inclined to believe that the best way of knowing is to love a great deal. Love that friend, that person, that thing, whatever you like, you’ll be on the right path to knowing more thoroughly, afterwards; that’s what I say to myself. But you must love with a high, serious intimate sympathy, with a will, with intelligence, and you must always seek to know more thoroughly, better, and more."`}</p>
    </blockquote>
    <p>{`It is this kind of intimacy, with ourselves and with our world, which most reliably `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=Ym4Rpd72tq8"
      }}>{`leads us home`}</a>{`, revealing along the way our purpose. You will find it at the heart of zen koans, great art works, and moving conversations alike: something which resists solution or conclusion, but insists that your current perception of the world `}<em parentName="p">{`dis-solve`}</em>{` so that grace can once more flow in the newly opened space; so that we can once more make contact with reality as it is and thereby fall in love all over again. As James P. Carse puts it in `}<a parentName="p" {...{
        "href": "/build/games/infinite"
      }}>{`Finite and Infinite Games`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"Touch is a characteristically paradoxical phenomenon of infinite play. I am not touched by an other when the distance between us is reduced to zero. I am touched only if I respond from my own center - that is, spontaneously, originally. But you do not touch me except from your own center, out of your own genius. Touching is always reciprocal. You cannot touch me unless I touch you in response."`}</p>
    </blockquote>
    <p>{`So much of our purpose in amongst all these digital marks comes down to “spirited connectivity”, that which opens up a channel for a response from your own centre which must, and indeed can only, come from that within you which loves, unconditionally.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What is the prerequisite for building a better web together?`}</p>
        <p>{`Knowing how I myself might be better.`}</p>
      </Card>
    </Flash>
    <hr></hr>
    <p>{`If you enjoyed this article and want to stumble around Brainpickings more, we recommend you start here:`}</p>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Hope`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/mod0-img/impossible.jpg",
            "alt": "Vaclav Havel"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://www.themarginalian.org/2019/09/22/vaclav-havel-hope/" mdxType="Button">
    Read It
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      